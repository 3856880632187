<template>
  <div>
    <div class='andmore' v-show='isNavOverflow'>
      <!--    <div class="andmore">-->
      <el-button @click='goToPage' circle icon='el-icon-arrow-right' size='medium' title='展开所有菜单'
                 type=''></el-button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'isCollapse'])

  },
  props: {
    isNavOverflow: {
      type: Boolean
    }
    ,
    navMenus: {
      type: Array,
      required: true
    }
  }
  ,
  methods: {
    goToPage() {
      //判断是否当前页面
      // console.log(this.$route.name)
      if (this.$route.name !== 'myNav')
        this.$router.push({ name: 'myNav' })
    }

  }
}
</script>

<style scoped>

</style>
