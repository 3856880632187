<template>
  <div class="logisticsBox">
    <el-popover
        placement="bottom"
        v-model="visible"
        trigger="manual"
        width="500"
        @hide='closePopover'
    >
      <div class="popoverTitle"> 单号查询
        <span @click="handleClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="LogisticsSearch">
        <el-input v-model="searchData.nu" class="input-with-select" clearable placeholder="请输入快递单号"
                  size="medium" @blur="isSFnu" @clear="resultShow=false" @keyup.enter.native="isSFnu">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-input v-if="showPhone" v-model="searchData.phone" class="input-with-select"
                  clearable maxlength="11"
                  placeholder="请输入收或寄件人手机号"
                  show-word-limit size="medium"
                  style="margin-top: 16px"
                  @blur="handleSearch" @clear="resultShow=false" @keyup.enter.native="handleSearch">
          <el-button slot="append" icon="el-icon-phone-outline"></el-button>
        </el-input>
        <el-card v-show="resultShow" shadow="never" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>查询结果：<span style="color: #409eff">{{ logisticMessage.com_label }}</span></span>
            <span style="float: right;color: red;cursor: pointer" @click="handleClose"><i
                class="el-icon-close"></i></span>
          </div>
          <logistic-time-line :logisticData="logisticList"></logistic-time-line>
        </el-card>
      </div>
      <i slot="reference" class="el-icon-search" @click="searchBtn"></i>
    </el-popover>
  </div>
</template>

<script>
// import mockApi from '@/mock/mockApi'
// import '@/mock/index'
import { EventBus } from '@/eventBus/event-bus'
import logisticTimeLine from '@/components/LogisticTimeLine.vue'
import logisticInfo from '../components/LogisticInfo.vue'

export default {
  name: 'LogisticsSearch',
  computed: {
    logisticInfo() {
      return logisticInfo
    }
  },
  components: { logisticTimeLine },
  data() {
    return {
      searchData: { nu: null, phone: null },
      logisticMessage: {},
      logisticList: [],
      resultShow: false,
      showPhone: false,
      visible: false
    }
  },
  mounted() {
    EventBus.$on('searchLogistic', value => {
      this.searchData.nu = value.nu
      this.searchData.phone = value.phone || null
      this.isSFnu()
      this.resultShow = false
      this.visible = true
    })

  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchData.nu) {
        //去掉单号的空格
        condition.nu = this.searchData.nu.replace(/\s*/g, '')
      }
      if (this.searchData.phone) {
        condition.phone = this.searchData.phone
      }
      return condition
    },
    isSFnu() {
      this.showPhone = String(this.searchData.nu).includes('SF')
      if (!this.showPhone) {
        this.searchBtn()
      }
    },
    handleSearch() {
      if (this.showPhone && String(this.searchData.phone).length === 11) {
        this.searchBtn()
      } else if (this.searchData.phone && this.searchData.phone !== '') {
        this.$message.error('查询顺丰快递需要输入正确收或寄件人手机号')
      }
    },
    handleClose() {
      this.visible = false
    },
    async searchBtn() {
      this.visible = true
      let searchCondition = this.handleSearchCondition()
      if (searchCondition?.nu) {
        let { info } = await this.$api.searchLogisticsInfoList(searchCondition)
        if (info) {
          this.logisticMessage = info || {}
          this.logisticList = this.logisticMessage?.lastResult?.data || this.logisticMessage?.data || info?.data || []
          this.resultShow = true
        }
      } else this.resultShow = false

    },
    closePopover() {
      //初始化
      this.searchData = {
        nu: null,
        phone: null
      }
      this.showPhone = false
      this.resultShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.logisticsBox {
  width: 20px;
  height: 30px;
  color: #606266;
  margin-top: calc(100% - 18px);
  font-size: 1.3rem;
  cursor: pointer;
}

.logisticsBox:hover {
  background: rgba(0, 0, 0, .025);
  color: #ff3176;
}

.LogisticsSearch {
  //height: 100px;

  > span {
    display: block;
    margin-top: 10px;
    color: #606266;
  }

}

.searchList {
  > span {
    margin-right: 10px;
  }
}

.inputStyle {
  width: 99%;
  display: block;
  margin: 10px 10px 20px 10px;
  border-radius: 0;
  /*border: 1px solid red;*/
}

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}

</style>
