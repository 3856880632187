<template>
  <div v-if='isShow'>
    <a>WebSocket服务</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WsTag',
  data() {
    return {
      WsUrl: null,
      WebSocket: null,
      timeout: 30 * 1000, // 心跳检测间隔 ms
      severTimeout: 3 * 1000, // 服务器超时时间，也就是websocket关闭重启时间 ms
      reconnectTimeout: 3 * 1000, // websocket重连时间间隔 ms
      timeoutObj: null,
      serverTimeoutObj: null,
      lockReconnect: false, // 连接状态标识
      token: '',//用户token
      retry: 10,        // 重连次数
      /** 主命令字定义 **/
      MainCmd: {
        CMD_SYS: 1, /** 系统类（主命令字）- 客户端使用 **/
        CMD_NOTIFY: 2 /** 消息类（主命令字）- 客户端使用 **/
      },
      SubCmd: {
        //系统子命令字，对应MainCmd.CMD_SYS
        LOGIN_FAIL_RESP: 100,
        HEART_ASK_REQ: 101,
        HEART_ASK_RESP: 102,
        BROADCAST_MSG_REQ: 103,
        BROADCAST_MSG_RESP: 104
      }
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    url: {
      type: String,
      default() {
        return `${process.env.VUE_APP_WS_URL}`
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {},
  mounted() {
    // this.initWebSocket()
  },
  beforeDestroy() {
    this.wsClose()
  },
  methods: {
    /* =============================================
     WebSocket 推送
    =============================================*/
    // websocket初始化
    initWebSocket() {
      // console.log('init-ws')
      try {
        this.createWebSocket()
      } catch (e) {
        this.reconnect()
      }
    },
    // websocket实例
    createWebSocket() {
      // websoket地址
      let url = `${this.url}?token=${this.token}`

      if (url && url.length > 0) {
        this.WebSocket = new WebSocket(url)
        //
        this.WebSocket.onopen = res => {
          this.reset()
          this.wsOnOpen(res)
        }
        this.WebSocket.onmessage = e => {
          this.reset()
          this.wsOnMessage(e)
        }
        this.WebSocket.onerror = err => {
          this.reconnect()
          this.wsOnError(err)
        }
        this.WebSocket.onclose = res => {
          // this.reconnect()
          this.wsOnClose(res)
        }
      } else {
        this.$notify.warning('即时通讯地址为空')
      }

    },

    /* 事件 */
    wsOnOpen(e) {
      // 告诉服务端， 本次ws连接建立用于哪个场景
      this.$emit('wsOnOpen', { url: e.currentTarget.url, type: e.type })
      // this.wsSend({cmd: this.cmd, res: res})
    },
    wsOnMessage(e) {
      // this.debugLogger('wsOnMessage:', e)
      const str = e.data
      if (!str) return
      let body = JSON.parse(str)
      // let cmd = body['cmd'];
      // let scmd = body['scmd'];
      // let data = body['data'];
      // this.debugLogger("websocket wsOnMessage >>>  cmd=" + cmd + "  scmd=" + scmd + "  data=");
      // this.debugLogger(data)

      this.$emit('wsOnMessage', body)
    },
    wsOnClose(e) {
      // this.debugLogger('closed:', e)
      this.$emit('wsOnClose', { code: e.code, type: e.type })
      // window.console.log('ws推送已关闭！')
    },
    wsOnError(e) {
      // this.debugLogger('Error:', e)
      this.$emit('wsOnError', e.type)
      // window.console.log('ws异常', res)
    },
    /* 方法 */
    wsSend(data, cmd, scmd) {
      // this.debugLogger("websocket Send >>>  cmd=" + cmd + "  scmd=" + scmd + "  data=");
      // this.debugLogger(data)
      let pack_data = JSON.stringify({
        data: data,
        cmd: cmd,
        scmd: scmd
      })
      if (this.WebSocket.readyState === this.WebSocket.OPEN) {
        this.WebSocket.send(pack_data)
      }

    },
    wsClose() {
      if (this.WebSocket && this.WebSocket.readyState === this.WebSocket.OPEN) {
        this.WebSocket.close()
      }
    },

    /* 心跳检测 */
    heartAsk() {
      this.timeoutObj = setInterval(() => {
        let cmd = this.MainCmd.CMD_SYS
        let scmd = this.SubCmd.HEART_ASK_REQ
        // send内容不可更改！！！发送内容与后端商定
        if (this.WebSocket.readyState === this.WebSocket.OPEN) {
          let data = {}
          data['time'] = (new Date()).valueOf()
          this.wsSend(data, cmd, scmd)
        } else {
          clearInterval(this.timeoutObj)
        }
      }, this.timeout)
    },
    reset() {
      this.timeoutObj && clearTimeout(this.timeoutObj)
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
      this.heartAsk()
    },

    /* 计数，避免websocket重复连接 */
    reconnect() {
      if (this.lockReconnect) {
        return
      }
      this.lockReconnect = true

      if (this.retry === 0) {
        return
      }
      // 没连接上会一直重连，设置延迟避免请求过多
      // tt && clearTimeout(tt);
      setTimeout(() => {
        this.initWebSocket()
        this.lockReconnect = false
        this.retry--
      }, this.reconnectTimeout)
    },
    setUserToken(token) {
      this.token = token
    }

    /* =====  End of WebSocket 推送  ======*/
  }
}
</script>

<style scoped>

</style>
