<template>
  <div class='mars-ape-layout-header'>
    <!--<Snow></Snow>-->
    <div class='imgs' :title="bannerTitle" :style="{ width:isCollapse?'100%':'90%' , }">
      <img :src='bannerImg'/>
    </div>

    <div class="header-left">
      <div class="header-nav-logo">
        <a href="/home">
          <img :src="siteInfo.logo" alt="logo"/>
        </a>
      </div>
    </div>

    <div class="header-middle">
      <TopMenu @isMore="isMore" :navMenus="navMenus"/>
    </div>
    <div class="isMore">
      <MoreBtn :isNavOverflow="moreBtn" :navMenus="navMenus"></MoreBtn>
    </div>
    <div class="header-right">
      <span>
<LogisticsSearch></LogisticsSearch>
<!--        <HelpSearch></HelpSearch>-->
      </span>

      <span class="right-notice">
        <!--       商务延期消息通知-->
        <!--        <DelayNotify :visible.sync="dialogNotifyVisible" v-if="userPermissions.indexOf('business_manage_delay_notify') > -1" />-->
        <!--       系统通知广播-->
        <NotifyBroadcast @recvNotify='recvNotify'></NotifyBroadcast>
        <!--       查看系统消息-->
        <el-link @click='notifyPage'>
          <el-badge :value='notifyTotal' class='notify-total' type='primary'>
            <i class='el-icon-bell'></i>
          </el-badge>
        </el-link>
      </span>
      <el-dropdown @command='handleCommand'>
        <span class='right-account'>
<!--          <div class="festival_cat ">-->
          <!--            <img :src="festival_cat">-->
          <!--          </div>-->
          <!--圣诞帽-->
          <span class='right-account-avatar'>
            <img :src='avatar' alt='avatar' style='border-radius: 50%; object-fit: fill' />
          </span>
          <span class='right-account-name' :title="userInfo['role_name']">
            {{ userInfo['nickname'] }}
          </span>
          <i class='el-submenu__icon-arrow el-icon-arrow-down'></i>
        </span>

        <el-dropdown-menu slot='dropdown'>
          <el-dropdown-item disabled command=''>
            <i class='iconfont el-icon-user-solid'></i>
            <span>{{ userInfo['role_name'] }}</span>
          </el-dropdown-item>
          <el-dropdown-item divided command='user-workbench'>
            <i class='iconfont icon-fujian2'></i>
            <span>工作台</span>
          </el-dropdown-item>
          <el-dropdown-item divided command='user-profile'>
            <i class='iconfont icon-touxiang'></i>
            <span>个人中心</span>
          </el-dropdown-item>
          <el-dropdown-item divided command='logout'><i class='iconfont icon-tuichudenglu2'></i><span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class='apexWeather'>
        <el-tooltip class='item' effect='light' placement='bottom'>
          <div slot='content'>
            <span v-html='nextWeather'></span>
          </div>
          <div>
            <div class='triangle' :style="{borderTopColor:borderTopColor}">
            </div>
            <div class='weatherBox'>
              <svg-icon :fill='fillColor' icon-class='sun' v-if="bgName==='sun'"></svg-icon>
              <svg-icon :fill='fillColor' icon-class='cloudy' v-if="bgName==='cloudy'"></svg-icon>
              <svg-icon :fill='fillColor' icon-class='cloudy' v-if="bgName==='rain'"></svg-icon>
            </div>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import BreadCrumb from '@/components/BreadCrumb'
import logo_header from '@/assets/festival/logo_sider-sd.png'
import avatar from '@/assets/avatar.png'
import WsTag from '@/components/WsTag'
import NotifyBroadcast from '@/pages/notify/NotifyBroadcast'
import festival_cat from '@/assets/festival/hat.png'
// import DelayNotify from '@/pages/business/DelayNotify'
import TopMenu from '@/layouts/TopMenu'
import Snow from '@/components/Style/snow.vue'
import MoreBtn from '@/layouts/MoreBtn.vue'
import menus from '@/json/menus.json'
import axios from 'axios'
import BonusEdit from '@/pages/performance/bonus/BonusEdit.vue'
import bannerImg from '@/assets/festival/banner.png'
import wmbn from '@/assets/festival/wmbn.png'
import LogisticsSearch from '@/layouts/LogisticsSearch.vue'

export default {
  data() {
    return {
      avatar: avatar,
      festival_cat: festival_cat,
      siteInfo: {
        logo: logo_header,
        name: '星象仪'
      },

      dialogNotifyVisible: false, //商务逾期提醒框
      moreBtn: false,
      navMenus: menus,
      env: false,
      weatherInfo: [],
      todayInfo: {},
      weatherI: 0,
      bgName: 'rain',
      nextWeather: '',
      bannerImg: bannerImg,
      fillColor: 'currentColor',
      borderTopColor: '#e6f4fa',
      bannerTitle:''

    }
  },
  components: {
    BonusEdit,
    TopMenu,
    BreadCrumb,
    NotifyBroadcast,
    WsTag,
    Snow,
    MoreBtn,
    LogisticsSearch
    // DelayNotify,
  },

  computed: {
    ...mapGetters(['userInfo', 'routePath', 'userPermissions', 'notifyTotal', 'isCollapse'])
  },
  methods: {
    ...mapMutations(['handleUserInfo', 'changeNotifyTotal', 'handleRoutePath']),
    // 处理下拉菜单指令
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.handleLogout()
          break
        case 'user-profile':
          this.$router.push('/user/profile_edit')
          break
        case 'user-workbench':
          this.$router.push('/profile/workbench')
          break
      //   user-workbench
      }
    },
    // 处理退出
    handleLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: `/login?redirect=${this.$route.fullPath}` })
        // location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    notifyPage() {
      // this.$router.push('/notify')
      window.location.href = '/notify'
    },
    async getNotifyUnreadTotal() {
      let { total } = await this.$api.getNotifyTotal({})
      this.changeNotifyTotal(total)
    },
    recvNotify() {
      this.getNotifyUnreadTotal()
      // this.changeNotifyTotal(100)
    },
    isMore(value) {
      this.moreBtn = value
    },
    async getWeather() {
      // let weatherInfo = []
      let { data } = await axios.get('https://restapi.amap.com/v3/weather/weatherInfo', {
        params: {
          key: 'fa8bf7d4878d041a4e3bcb71aa79e891',
          city: 330100,
          extensions: 'all',
          output: 'JSON'
        }
      })
      if (data && Number(data.status) === 1) {
        this.weatherInfo = data
        let todayWeath = data.forecasts?.[0].casts?.[0]
        let _ = todayWeath.dayweather
        this.todayInfo = {}
        this.bgName = _.search('雨') > -1 ? 'rain' : _.search('晴') > -1 ? 'sun' : 'cloudy'
        this.nextWeather = `未来三天天气(杭州地区):<br> 【${this.switchWeek(Number(todayWeath.week))}】白天:<span style='color: #f24b88'>${todayWeath.dayweather}(${todayWeath.daytemp}°C)</span>,晚上：<span style='color: #f24b88'>${todayWeath.nightweather}(${todayWeath.nighttemp}°C)</span><br>
 【${this.switchWeek(Number(data.forecasts?.[0].casts?.[1].week))}】白天:<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[1].dayweather}(${data.forecasts?.[0].casts?.[1].daytemp}°C)</span>,晚上：<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[1].nightweather}(${data.forecasts?.[0].casts?.[1].nighttemp}°C)</span><br>
 【${this.switchWeek(Number(data.forecasts?.[0].casts?.[2].week))}】白天:<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[2].dayweather}(${data.forecasts?.[0].casts?.[2].daytemp}°C)</span>,晚上：<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[2].nightweather}(${data.forecasts?.[0].casts?.[2].nighttemp}°C)</span><br>
 【${this.switchWeek(Number(data.forecasts?.[0].casts?.[3].week))}】白天:<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[3].dayweather}(${data.forecasts?.[0].casts?.[3].daytemp}°C)</span>,晚上：<span style='color: #f24b88'>${data.forecasts?.[0].casts?.[3].nightweather}(${data.forecasts?.[0].casts?.[3].nighttemp}°C)</span><br>
`
      }
    },
    switchWeek(data) {
      let d = ''
      switch (data) {
        case 1:
          d = '周一'
          break
        case 2:
          d = '周二'
          break
        case 3:
          d = '周三'
          break
        case 4:
          d = '周四'
          break
        case 5:
          d = '周五'
          break
        case 6:
          d = '周六'
          break
        case 7:
          d = '周日'
          break
      }
      return d
    },

    bindNotifyMessage() {
      // 绑定事件-新消息接收处理
      if (this.$globalWs.ws) {
        this.$globalWs.ws.on('ev_new_message', (msg, data) => {
          this.$notify.success({
            title: '新消息提示',
            message: '您有新的消息，请注意查收！',
            onClick: () => {
              this.msg = true
            }
          })
          // if (data.length > this.msgList.length) {
          //   this.$notify.success({
          //     title: '新消息提示',
          //     message: '您有新的消息，请注意查收！',
          //     onClick: () => {
          //       this.msg = true
          //     }
          //   })
          // }
          this.msgList = data
        })
      }

    },
    getFestival() {
      let today = new Date().toLocaleDateString()
      if (today === '2025/3/8') {
        this.bannerImg = wmbn
        this.borderTopColor = '#f4c7c8'
        this.bannerTitle='三八妇女节快乐！'
      } else {
        this.bannerImg = bannerImg
        this.borderTopColor='#e6f4fa'
        this.bannerTitle=''
      }

    }
  },
  mounted() {
    this.getFestival()
    let userInfo = JSON.parse(localStorage.getItem('user_info'))
    this.handleUserInfo(userInfo)
    // 头像
    if (this.userInfo.avatar) this.avatar = this.userInfo.avatar
  },
  created() {
    this.getWeather()
  }

}
</script>

<style lang='stylus' scoped>

.mars-ape-layout-header
  height 64px
  width 100%
  min-width 1024px
  background #fff
  box-shadow 0 1px 4px rgba(0, 21, 41, .08)
  overflow hidden

.mars-ape-layout-header > .header-right
  height 64px
  line-height 64px
  width 260px
  min-width 240px
  float right
  display flex

.mars-ape-layout-header > .header-left
  display flex
  float left

.mars-ape-layout-header > .header-left > .header-index-trigger
  font-size 20px
  // height 64px
  cursor pointer
  transition all .3s, padding 0s
  padding 10px 20px

i.header-index-trigger:hover
  background rgba(0, 0, 0, .025)

.header-right .right-account, .header-right .right-notice
  margin 0 6px
  display inline-block
  transition all .3s
  cursor pointer
  color: rgba(0, 0, 0, .65)
  margin-left 12px

.right-notice .el-badge
  line-height 30px

.right-account-avatar
  width 30px
  // line-height 48px
  display inline-block
  // margin 10px 8px 10px 0
  vertical-align top
  //border 1px solid #b8bec3
  margin 0 12px


.right-account-avatar > img
  width 100%
  display block
  //border 1px solid #b8bec3
  margin-top calc(100% - 15px)

.right-account-name
  font-size 14px

.right-notice:hover, .right-account:hover
  background rgba(0, 0, 0, .025)

.el-dropdown-menu__item
  padding 5px 12px
  line-height 22px
  font-weight 400
  color rgba(0, 0, 0, 0.65)

.el-dropdown-menu__item > .iconfont
  margin-right 8px

.el-dropdown-menu
  padding 4px 0
  margin 0

.el-dropdown-menu__item--divided
  padding-top 0
  line-height 28px

.el-dropdown-menu__item--divided::before
  margin 0 -12px

.el-dropdown-menu.el-popper
  min-width 160px
  top 56px !important

.el-popper .popper__arrow
  border-width 0

.el-popper .popper__arrow::after
  border-width 0
</style>

<style scoped lang='scss'>

/**
      消息通知数的图标样式
       */
.header-nav-logo {
  margin-left: 1px;
  line-height: 64px;
  height: 64px;
  padding: 8px;
}

.header-nav-logo img {
  line-height: 48px;
  height: 48px;
  position: relative;
  padding-left: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /*background: #002140;*/
  overflow: hidden;
}

.right-account {
  width: 160px;
}

.notify-total {
  /*margin-top: 10px;*/
  margin-right: 5px;
  font-size: 1.3rem;
}

.header-middle {
  width: calc(100% - 448px - 100px);
  height: 100%;
  display: flex;
  float: left;
}

.isMore {
  width: 50px;
  height: 65px;
  display: flex;
  float: left;
  flex-direction: row; /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center;
}

@media (min-width: 1024px) {
  .mars-ape-layout-header > .header-left {
    width: 220px;
    min-width: 220px;
  }
}

@media (max-width: 1024px) {
  .header-nav-logo img {
    width: 112px;
    height: auto;
  }


}

.weather {
  width: 50px;
  height: auto;
  //border: 1px solid red;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  > span {
    font-family: "Arial Black";
    font-size: 12px;
    width: 100%;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.apexWeather {
  //width: 60px;
  height: 30px;
  //border: 1px solid red;
  right: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.triangle {
  width: 0px;
  height: 0px;
  border-top: 50px solid #e6f4fa;
  border-left: 50px solid transparent;
  position: absolute;
  right: 0;
  z-index: 2;
}

.weatherBox {
  top: 0;
  position: relative;
  z-index: 2;
  height: 10px;
  width: 100%;
  display: flex !important;
  padding-top: 10px;

  > * {
    color: white;
    display: flex !important;
  }

}

.imgs {
  //border: 1px solid red;
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 100%;
  height: 30px;
  overflow: hidden;

  > img {
    display: block;
    //width: 150%;
    //height: 30px;

    position: absolute;
  }
}
</style>
