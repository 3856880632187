<template>
  <div>
    <WsTag @wsOnMessage="wsOnMessage" ref="refWsNotifyTag" :url="wsUrl"></WsTag>
  </div>
</template>

<script>
import WsTag from "@/components/WsTag";
import {mapGetters} from "vuex";

export default {
  name: "NotifyBroadcast",
  components: {
    WsTag
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      wsUrl: null,
    }
  },
  created() {
    //webSocket地址
    if (process.env.NODE_ENV === "development")
      this.wsUrl = `${process.env.VUE_APP_WS_URL}/notify`
    else {
      const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
      const hostname = window.location.hostname
      this.wsUrl = `${wsProtocol}//${hostname}${process.env.VUE_APP_WS_URL}/notify`
    }

  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('user_info'))

    let token = JSON.stringify({account: userInfo.id})
    // this.debugLogger(token)
    if (token) {
      this.$refs['refWsNotifyTag'].setUserToken(token)
    }
    if (this.wsUrl)
      this.$refs['refWsNotifyTag'].initWebSocket()
  },
  methods: {
    wsOnMessage(body) {
      // let cmd = body['cmd'];
      let scmd = body['scmd'];
      let data = body['data'];
      // this.debugLogger(data);
      // 逻辑处理：心跳、登录上线、下线通知、创建红人通知（权限）
      switch (scmd) {
        case 100:

          break;
        case 101:
        case 102:
          // console.log('心跳请求响应处理（响应需要编写对应的路由处理逻辑）')

          break;
        case 105:
          //登陆成功消息下发命
          // this.$notify.info('用户上线')
          break;
        case 5:
          //创建红人后下发的命令
          // eslint-disable-next-line no-case-declarations
          const msg = data.data.notify
          this.$notify.info(
              {
                title: '创建消息',
                dangerouslyUseHTMLString: true,
                message: '<el-link href="/notify">' + msg + '</el-link>',
                duration: 0
              }
          )
          this.$emit('recvNotify', 1);
          break;
      }

    },
  },
  destroyed() {
    if (this.$refs['refWsNotifyTag']) {
      this.$refs['refWsNotifyTag'].wsClose()
    }
  }
}
</script>

<style scoped>

</style>
